import { Link, NavLink } from "react-router-dom";
import { ReactComponent as Label } from "../../assets/images/label.svg";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { ReactComponent as ActiveLogo } from "../../assets/images/logoActive.svg";
import { ReactComponent as Vinyl } from "../../assets/images/vinyl.svg";
import { ReactComponent as Cassette } from "../../assets/images/cassette.svg";
import { ReactComponent as Merch } from "../../assets/images/merch.svg";
// import { ReactComponent as Blog } from "../../assets/images/blog.svg";
import { ReactComponent as Cart } from "../../assets/images/cart.svg";
import { ReactComponent as Burger } from "../../assets/images/burger.svg";
import { ReactComponent as CloseBurger } from "../../assets/images/closeBurger.svg";
import { ReactComponent as CartMobile } from "../../assets/images/cart-mobile.svg";
import { ReactComponent as Search } from "../../assets/images/search.svg";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useLocation } from "react-router-dom";

export const Header = ({
  changeLanguage,
}: {
  changeLanguage: (language: string) => void;
}) => {
  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searching, setSearching] = useState(false);
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [quantity, setQuantity] = useState(0);
  const [hint, setHint] = useState("");
  const inputRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/cart") {
      setQuantity(0);
    } else {
      const quantityArray = localStorage.getItem("userCartQuantities");
      let total = 0;
      if (quantityArray) {
        const parsedQuantity = JSON.parse(quantityArray);

        for (const el of parsedQuantity) {
          total += el;
        }
        setQuantity(total);
      }
    }
  }, [location.pathname]);

  const fetchSuggestions = async (input: string) => {
    try {
      const { data } = await axios.get(
        `https://dywlf.com/api/search/result?query=${input}&language=${i18n.language}`
      );

      if (data.length > 0) {
        const firstSuggestion =
          data[0].artistName || data[0].albumName || data[0].name;
        if (firstSuggestion.toLowerCase().startsWith(input.toLowerCase())) {
          setHint(firstSuggestion);
        } else {
          setHint("");
        }
        setSuggestions(data);
      } else {
        setHint("");
      }
    } catch (error) {}
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    setQuery(input);

    if (input.length > 1) {
      fetchSuggestions(input);
    } else {
      setSuggestions([]);
      setHint("");
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      localStorage.setItem("searchSuggestions", JSON.stringify(suggestions));
      localStorage.setItem("searchQuery", query);
      window.location.href = "/search";
    }

    if (hint && (e.key === "Tab" || e.key === "ArrowRight")) {
      e.preventDefault();
      setQuery(hint);
      setHint("");
    }
  };

  const onSearchInputFocus = () => {
    setSearching(true);
  };

  const onSearchInputBlur = () => {
    setSearching(false);
    setHint("");
    setQuery("");
  };

  const { i18n, t } = useTranslation();

  return (
    <>
      <header className="header">
        <NavLink
          to="/"
          className={`header__link header__logo--hovering${
            Math.floor(Math.random() * 3) + 1
          }`}
        >
          <div className="header__link--wrapper">
            <Logo className="header__icon--logo" />
            <ActiveLogo className="header__icon--activeLogo" />
            <p className="header__link--title">DYWLF?</p>
          </div>
        </NavLink>
        <NavLink to="label" className="header__link header__link--label">
          <div className="header__link--wrapper">
            <Label className="header__icon--label" />
            <p className="header__link--title">{t("LabelTitle")}</p>
          </div>
        </NavLink>
        <NavLink to="vinyl" className="header__link header__link--vinyl">
          <div className="header__link--wrapper">
            <Vinyl className="header__icon--vinyl" />
            <p className="header__link--title">{t("VinylTitle")}</p>
          </div>
        </NavLink>
        <NavLink to="cassette" className="header__link header__link--cassette">
          <div className="header__link--wrapper">
            <Cassette className="header__icon--cassette" />
            <p className="header__link--title">{t("CassetteTitle")}</p>
          </div>
        </NavLink>
        <NavLink to="merch" className="header__link header__link--merch">
          <div className="header__link--wrapper">
            <Merch className="header__icon--merch" />
            <p className="header__link--title">{t("MerchTitle")}</p>
          </div>
        </NavLink>
        {/* <NavLink to="blog" className="header__link header__link--blog">
          <Blog className="header__icon--blog" />
        </NavLink> */}
        <NavLink to="cart" className="header__link header__link--cart">
          <div className="header__link--wrapper">
            <div className="header__quantity--wrapper">
              <Cart className="header__icon--cart" />
              {quantity > 0 ? (
                <p className="header__cart--quantity">{quantity}</p>
              ) : (
                ""
              )}
            </div>
            <p className="header__link--title">{t("CartTitle")}</p>
          </div>
        </NavLink>
      </header>
      <header className="header__mobile">
        <div className="header__mobile--thumb">
          {isBurgerMenuOpen ? (
            <CloseBurger
              onClick={() => {
                setIsBurgerMenuOpen(false);
                setIsSearchOpen(false);
              }}
            />
          ) : (
            <Burger
              onClick={() => {
                setIsBurgerMenuOpen(true);
              }}
            />
          )}
          {!isBurgerMenuOpen && (
            <Link to="/">
              <p className="header__mobile--title">DYWLF?</p>
            </Link>
          )}
          <div
            className="header__mobile--wrapper"
            style={{ width: isSearchOpen && isBurgerMenuOpen ? "100%" : "" }}
          >
            {isBurgerMenuOpen && (
              <div
                className="header__search--wrapper"
                style={{
                  width: isSearchOpen && isBurgerMenuOpen ? "85%" : "",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Search
                  className={
                    isSearchOpen && isBurgerMenuOpen
                      ? "header__search--icon"
                      : ""
                  }
                  onClick={() => {
                    setIsSearchOpen(true);
                  }}
                />
                {isSearchOpen && isBurgerMenuOpen && (
                  <>
                    <input
                      type="text"
                      className="header__mobile--input"
                      value={query}
                      onChange={handleInputChange}
                      onKeyDown={handleKeyDown}
                      ref={inputRef}
                      onFocus={onSearchInputFocus}
                      onBlur={onSearchInputBlur}
                    />
                    {searching && (
                      <div className="header__input--text">
                        <span className="header__input--textActive">
                          {query}
                        </span>
                        {hint.slice(query.length)}
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
            <Link to="/cart" style={{ height: "17px" }}>
              <CartMobile />
            </Link>
          </div>
        </div>
        <div
          className={
            isBurgerMenuOpen
              ? "header__mobile--modal"
              : "header__mobile--modal header__modal--hidden"
          }
        >
          <div
            className="header__modal--item"
            onClick={() => {
              window.location.href = "/label";
            }}
          >
            <Label className="header__icon--label" />
            <p className="header__modal--title">{t("LabelTitle")}</p>
          </div>
          <div
            className="header__modal--item"
            onClick={() => {
              window.location.href = "/vinyl";
            }}
          >
            <Vinyl className="header__icon--vinyl" />
            <p className="header__modal--title">{t("VinylTitle")}</p>
          </div>
          <div
            className="header__modal--item"
            onClick={() => {
              window.location.href = "/cassette";
            }}
          >
            <Cassette className="header__icon--cassette" />
            <p className="header__modal--title">{t("CassetteTitle")}</p>
          </div>
          <div
            className="header__modal--item"
            onClick={() => {
              window.location.href = "/merch";
            }}
          >
            <Merch className="header__icon--merch" />
            <p className="header__modal--title">{t("MerchTitle")}</p>
          </div>
          <div className="header__modal--item">
            <div className="header__language--wrapper">
              <button
                type="button"
                onClick={() => {
                  changeLanguage("uk");
                }}
                className={
                  i18n.language === "uk"
                    ? "header__modal--langButton header__langButton--active"
                    : "header__modal--langButton"
                }
              >
                UA
              </button>
              <button
                type="button"
                onClick={() => {
                  changeLanguage("en");
                }}
                className={
                  i18n.language === "en"
                    ? "header__modal--langButton header__langButton--active"
                    : "header__modal--langButton"
                }
              >
                ENG
              </button>
            </div>
            <p className="header__modal--title header__modal--titleBlack">
              {t("HeaderLanguage")}
            </p>
          </div>
        </div>
      </header>
      <div
        className={isBurgerMenuOpen ? "header__mobile--backdrop" : ""}
        onClick={() => {
          setIsBurgerMenuOpen(false);
        }}
      ></div>
    </>
  );
};
