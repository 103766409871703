import { ReactComponent as LeftArrow } from "../../assets/images/leftArrow.svg";
import { ReactComponent as RightArrow } from "../../assets/images/rightArrow.svg";
import { Navigation, Pagination } from "swiper/modules";
import SwiperCore from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useEffect, useRef, useState } from "react";
import { Vinyl } from "../../interfaces/vinyl";
import { Cassette } from "../../interfaces/cassette";
import { getAllShowcases, getUsdExchange } from "../../services/axios";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { roundToHigherOrder } from "../../utils/roundToHigherOrder";
import { Loader } from "../Loader/Loader";

SwiperCore.use([Navigation, Pagination]);

export const Hero = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore | null>(null);
  const [showcase, setShowcase] = useState<Vinyl[] | Cassette[] | []>([]);
  const [exchangeRate, setExchangeRate] = useState(41);
  const [className, setClassName] = useState("");

  const { i18n, t } = useTranslation();

  useEffect(() => {
    const fetchShowcases = async () => {
      setShowcase(await getAllShowcases(i18n.language));
      setTimeout(() => {
        setClassName("loader__hidden");
      }, 200);
    };

    fetchShowcases();
  }, [i18n.language]);

  useEffect(() => {
    const fetchExchanceRate = async () => {
      setExchangeRate(await getUsdExchange());
    };

    fetchExchanceRate();
  }, []);

  const handleBulletClick = (index: number) => {
    if (swiperInstance) {
      swiperInstance.slideTo(index);
    }
  };

  const handleSlideChange = () => {
    if (swiperInstance) {
      setActiveIndex(swiperInstance.realIndex);
    }
  };

  return (
    <>
      {showcase?.length > 0 && (
        <>
          <section className="hero">
            <div className="hero__thumb">
              <Swiper
                onSwiper={(swiper: any) => setSwiperInstance(swiper)}
                className="hero__swiper"
                spaceBetween={2500}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                speed={450}
                allowTouchMove={window.innerWidth < 1000}
                loop={true}
                slidesPerView={1}
                onSlideChange={handleSlideChange}
              >
                {showcase?.map(({ _id, coverPicture, showcaseImage, type }) =>
                  type === "vinyl" ? (
                    <SwiperSlide key={_id + coverPicture}>
                      <Link to={`/release/${_id}`}>
                        <img
                          src={showcaseImage}
                          alt={t("HeroAltVinyl")}
                          className="hero__image--vinyl"
                        />
                        <img
                          src={coverPicture}
                          alt={t("HeroAltAlbum")}
                          className="hero__image--album"
                        />
                      </Link>
                    </SwiperSlide>
                  ) : (
                    <SwiperSlide key={_id + coverPicture}>
                      <Link to={`/release/${_id}`}>
                        <img
                          src={showcaseImage}
                          alt={t("HeroAltAlbum")}
                          className="hero__image--cassette"
                        />
                        <img
                          src={coverPicture}
                          alt={t("HeroAltVinyl")}
                          className="hero__image--wrapper"
                        />
                      </Link>
                    </SwiperSlide>
                  )
                )}
              </Swiper>
            </div>
            <div className="hero__data">
              <div className="hero__info--wrapper">
                <div className="hero__info">
                  <p className="hero__title">
                    {showcase[activeIndex]?.artistName}
                  </p>
                  <p className="hero__album">
                    {showcase[activeIndex]?.albumName}
                  </p>
                </div>
                <p className="hero__price">
                  {i18n.language === "uk"
                    ? showcase[activeIndex]?.uah !== 0
                      ? showcase[activeIndex]?.uah
                      : roundToHigherOrder(
                          showcase[activeIndex]?.usd * exchangeRate
                        )
                    : showcase[activeIndex]?.usd}
                  {t("Currency")}
                  {i18n.language === "uk" &&
                    showcase[activeIndex]?.uahSale !== 0 && (
                      <span className="release__price--sale">
                        <s>
                          {showcase[activeIndex]?.uahSale}
                          {t("Currency")}
                        </s>
                      </span>
                    )}
                  {i18n.language === "en" &&
                    showcase[activeIndex]?.usdSale !== 0 && (
                      <span className="release__price--sale">
                        <s>
                          {showcase[activeIndex]?.usdSale} {t("Currency")}
                        </s>
                      </span>
                    )}
                </p>
              </div>
              <Link to={`/release/${showcase[activeIndex]?._id}`}>
                <button
                  type="button"
                  className={
                    showcase[activeIndex]?.availability === "inStock"
                      ? "hero__button button__hovering"
                      : showcase[activeIndex]?.availability === "preOrder"
                      ? "hero__button button__hovering"
                      : "hero__button button__outOfStock"
                  }
                >
                  {showcase[activeIndex]?.availability === "inStock"
                    ? t("ButtonAvailabilityInStock")
                    : t("ButtonAvailabilityPreOrder")}
                </button>
              </Link>
              <div className="hero__next--wrapper">
                <p className="hero__next">{t("HeroNext")}:</p>
                <p className="hero__next">
                  {showcase[activeIndex + 1]?.artistName
                    ? showcase[activeIndex + 1]?.artistName
                    : showcase[0]?.artistName}
                </p>
                <p className="hero__next">
                  {showcase[activeIndex + 1]?.albumName
                    ? showcase[activeIndex + 1]?.albumName
                    : showcase[0]?.albumName}
                </p>
              </div>
              <div className="hero__button--wrapper">
                <button
                  type="button"
                  ref={prevRef}
                  className="hero__swipe--back swiper-button-prev"
                >
                  <LeftArrow />
                </button>
                <button
                  type="button"
                  ref={nextRef}
                  className="hero__swipe--next swiper-button-next"
                >
                  <RightArrow />
                </button>
              </div>
              <div
                className="hero__custom--pagination"
                style={{ display: "flex" }}
              >
                {showcase?.map(({ _id }, index) => (
                  <span
                    key={index + _id}
                    className={`hero__custom--bullet ${
                      index === activeIndex ? "active" : ""
                    }`}
                    onClick={() => handleBulletClick(index)}
                  ></span>
                ))}
              </div>
            </div>
          </section>
        </>
      )}
      <Loader className={className} />
    </>
  );
};
