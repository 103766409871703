import { useEffect, useState } from "react";
import { Title } from "../../components/Title/Title";
import { Sort } from "../../components/Sort/Sort";
import { ProductsList } from "../../components/ProductsList/ProductsList";
import { getAllVinyls } from "../../services/axios";
import { Vinyl } from "../../interfaces/vinyl";
import { useTranslation } from "react-i18next";
import { Pagination } from "../../components/Pagination/Pagination";
import { SEO } from "../../components/SEO/SEO";

export const VinylComp = () => {
  const [inStock, setInStock] = useState(true);
  const [direction, setDirection] = useState(true);
  const [data, setData] = useState<Vinyl[] | []>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { i18n, t } = useTranslation();

  useEffect(() => {
    const fetchAllVinyls = async () => {
      const data = await getAllVinyls(
        i18n.language,
        inStock ? "inStock" : "all",
        currentPage,
        18
      );
      setData(data?.vinyl);
      setTotalPages(data?.totalPages);
    };

    fetchAllVinyls();
  }, [i18n.language, inStock, currentPage]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <SEO
        title={t("VinylPageTitleSEO")}
        description={t("VinylPageDescriptionSEO")}
        link="vinyl"
        keywords="vinyl, store, вініл, винил, купить, придбати, records"
        imageCard="https://dywlf.com/logo512.png"
      />
      <div className="vinyl">
        <div className="container__two">
          <Title
            title={t("VinylTitle")}
            type={true}
            direction={direction}
            setDirection={setDirection}
          />
          <Sort
            inStock={inStock}
            setInStock={setInStock}
            data={data}
            setData={setData}
          />
          <ProductsList data={data} direction={direction} />
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
};
