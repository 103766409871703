import { useEffect, useState } from "react";
import { Title } from "../../components/Title/Title";
import { getAllMerchs } from "../../services/axios";
import { Merch } from "../../interfaces/merch";
import { MerchList } from "../../components/MerchList/MerchList";
import { useTranslation } from "react-i18next";
import { Pagination } from "../../components/Pagination/Pagination";
import { SortMerch } from "../../components/Sort/SortMerch";
import { SEO } from "../../components/SEO/SEO";
import { Loader } from "../../components/Loader/Loader";

export const MerchComp = () => {
  const [direction, setDirection] = useState(true);
  const [inStock, setInStock] = useState(true);
  const [data, setData] = useState<Merch[] | []>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [className, setClassName] = useState("");

  const { i18n, t } = useTranslation();

  useEffect(() => {
    const fetchAllMerchs = async () => {
      const data = await getAllMerchs(
        i18n.language,
        inStock ? "inStock" : "all",
        currentPage,
        18
      );
      setData(data?.merch);
      setTotalPages(data?.totalPages);
      setTimeout(() => {
        setClassName("loader__hidden");
      }, 200);
    };

    fetchAllMerchs();
  }, [i18n.language, inStock, currentPage]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <Loader className={className} />
      {data && (
        <>
          <SEO
            title={t("MerchPageTitleSEO")}
            description={t("MerchPageDescriptionSEO")}
            link="merch"
            keywords="merch, store, мерч, купить, придбати, buy"
            imageCard="https://dywlf.com/logo512.png"
          />
          <div className="cassette">
            <div className="container__two">
              <Title
                title={t("MerchTitle")}
                type={true}
                direction={direction}
                setDirection={setDirection}
              />
              <SortMerch
                inStock={inStock}
                setInStock={setInStock}
                data={data}
                setData={setData}
              />
              <MerchList data={data} direction={direction} />{" "}
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};
