import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

const DOMAIN = "https://dywlf.com";
const MAIN_KEYWORDS =
  "vinyl, store, вініл, винил, купить, придбати, records, label, indie, music";

const DEFAULT_IMAGE_CARD = "https://dywlf.com/logo512.png";
const DEFAULT_TITLE = "DYWLF?";
const DEFAULT_DESCRIPTION =
  "Інді-лейбл та онлайн-магазин вінілу, базується у місті Дніпро. Тут ти знайдеш багато цікавої музики!";

const FAVICON_SOURCE = "https://dywlf.com/favicon.ico";

interface SeoProps {
  title: string;
  description?: string;
  link?: string;
  keywords?: string;
  imageCard?: string;
  largeTwitterCard?: boolean;
  noIndex?: boolean;
  children?: React.ReactNode;
}

export const SEO = ({
  title = DEFAULT_TITLE,
  description = DEFAULT_DESCRIPTION,
  link,
  keywords,
  imageCard,
  largeTwitterCard = false,
  noIndex = false,
  children = null,
}: SeoProps) => {
  const { i18n } = useTranslation();

  const metaDesc = description ?? DEFAULT_DESCRIPTION;
  const metaLink = `${DOMAIN}/${link}`;

  const metaKeywords = keywords?.length
    ? MAIN_KEYWORDS + ", " + keywords
    : MAIN_KEYWORDS;

  let metaImageCard;

  if (imageCard) {
    if (imageCard.startsWith("https")) {
      metaImageCard = imageCard;
    } else {
      metaImageCard = DOMAIN + imageCard;
    }
  } else {
    metaImageCard = DEFAULT_IMAGE_CARD;
  }

  const metaRobots = noIndex ? "noindex, nofollow" : "index, follow";

  const twitterCardType = largeTwitterCard ? "summary_large_image" : "summary";

  return (
    <Helmet>
      <html lang={i18n.language} />
      <title>{title}</title>
      <meta name="description" content={metaDesc} />
      <link rel="canonical" href={metaLink} />
      <meta name="keywords" content={metaKeywords} />
      <meta name="robots" content={metaRobots} />
      <link rel="icon" content={FAVICON_SOURCE} />

      {/* OG Tags */}
      {/* https://ogp.me/ */}
      <meta property="og:url" title={metaLink} />
      <meta property="og:title" title={title} />
      <meta property="og:description" title={metaDesc} />
      <meta property="og:type" content="..." />
      <meta property="og:image" content={metaImageCard} />

      {/* Twitter tags */}
      {/* https://developer.twitter.com/en/docs/twitter-for-websites/cards/guides/getting-started */}
      <meta property="twitter:site" title="twitter username of website" />
      <meta property="twitter:title" title={title} />
      <meta property="twitter:description" title={metaDesc} />
      <meta
        property="twitter:creator"
        content="twitter username of webpage content"
      />
      <meta property="twitter:card" content={twitterCardType} />
      <meta property="twitter:image" content={metaImageCard} />

      {/* https://moz.com/blog/meta-referrer-tag */}
      <meta name="referrer" content="origin-when-cross-origin" />

      <link
        rel="alternate"
        hrefLang={i18n.language}
        href={`${DOMAIN}/${i18n.language}${link}`}
        key={i18n.language}
      />

      {children}
    </Helmet>
  );
};
