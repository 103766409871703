import { Title } from "../../components/Title/Title";
import { ReactComponent as Minus } from "../../assets/images/cartMinus.svg";
import { ReactComponent as Plus } from "../../assets/images/cartPlus.svg";
import { ReactComponent as Cross } from "../../assets/images/cartCross.svg";
import { useEffect, useState } from "react";
import { getUsdExchange, getUserCart } from "../../services/axios";
import { Sizes, SizesLowerCase } from "../../interfaces/sizes";
import { CartModal } from "../../components/CartModal/CartModal";
import { roundToHigherOrder } from "../../utils/roundToHigherOrder";
import { Processing } from "../../components/Processing/Processing";
import { useTranslation } from "react-i18next";
import { SEO } from "../../components/SEO/SEO";
import { Loader } from "../../components/Loader/Loader";
import { toast } from "react-toastify";

export const Cart = () => {
  const [userCart, setUserCart] = useState([]);
  const [userCartIds, setUserCartIds] = useState([]);
  const [userCartTypes, setUserCartTypes] = useState([]);
  const [userCartColors, setUserCartColors] = useState([]);
  const [userCartSizes, setUserCartSizes] = useState<Sizes[] | []>([]);
  const [userCartLimits, setUserCartLimits] = useState([]);
  const [quantities, setQuantities] = useState([]);
  const [exchangeRate, setExchangeRate] = useState(41);
  const [order, setOrder] = useState("");
  const [processing, setProcessing] = useState(false);
  const [isInfoOpened, setIsInfoOpened] = useState(false);
  const [className, setClassName] = useState("");

  const { i18n, t } = useTranslation();

  useEffect(() => {
    const invoiceId = localStorage.getItem("invoiceId");

    if (invoiceId) {
      setProcessing(true);
    }
  }, []);

  useEffect(() => {
    const fetchUserCart = async () => {
      setUserCart(
        await getUserCart(
          userCartIds,
          userCartTypes,
          userCartColors,
          userCartSizes,
          i18n.language
        )
      );
      setExchangeRate(await getUsdExchange());
      setTimeout(() => {
        setClassName("loader__hidden");
      }, 200);
    };

    fetchUserCart();
  }, [
    userCartIds,
    userCartTypes,
    userCartColors,
    userCartSizes,
    i18n.language,
  ]);

  useEffect(() => {
    setQuantities(
      JSON.parse(localStorage.getItem("userCartQuantities") ?? "[]")
    );
    setUserCartIds(JSON.parse(localStorage.getItem("userCartIds") || "[]"));
    setUserCartTypes(JSON.parse(localStorage.getItem("userCartTypes") || "[]"));
    setUserCartColors(
      JSON.parse(localStorage.getItem("userCartColors") || "[]")
    );
    setUserCartSizes(JSON.parse(localStorage.getItem("userCartSizes") || "[]"));
    setUserCartLimits(
      JSON.parse(localStorage.getItem("userCartLimits") || "{}")
    );
  }, []);

  function findIndexByColor(
    info: [
      {
        xl: number;
        l: number;
        m: number;
        s: number;
        xs: number;
        color: string;
        image: string;
        oneSizeAmount: number;
      }
    ],
    color: string
  ) {
    return info.findIndex((item) => item.color === color);
  }

  const updateQuantity = (
    index: number,
    value: number,
    availability: string,
    amount: number,
    info: [
      {
        xl: number;
        l: number;
        m: number;
        s: number;
        xs: number;
        color: string;
        image: string;
        oneSizeAmount: number;
      }
    ]
  ) => {
    const quantityExist = localStorage.getItem("userCartQuantities");
    const limitsExist = localStorage.getItem("userCartLimits");
    let quantities = quantityExist ? JSON.parse(quantityExist) : [];
    let limits = limitsExist ? JSON.parse(limitsExist) : {};
    let canIncreaseLimit = true;

    if (!Array.isArray(quantities)) {
      quantities = [];
    }

    quantities[index] = (quantities[index] || 1) + value;

    if (availability === "inStock" && quantities[index] > amount) {
      quantities[index] = amount;
      canIncreaseLimit = false;
    }

    if (info && availability === "inStock") {
      const size = userCartSizes[index].toLocaleLowerCase() as SizesLowerCase;
      if (
        quantities[index] >
        info[findIndexByColor(info, userCartColors[index])][size]
      ) {
        quantities[index] =
          info[findIndexByColor(info, userCartColors[index])][size];
      }
    }
    if (quantities[index] < 1) quantities[index] = 1;

    if (limits[userCartTypes[index]] && canIncreaseLimit) {
      limits[userCartTypes[index]] = limits[userCartTypes[index]] + value;
      if (limits[userCartTypes[index]] < 1) {
        limits[userCartTypes[index]] = 1;
      } else if (limits[userCartTypes[index]] > 5) {
        limits[userCartTypes[index]] = 5;
        quantities[index] -= 1;
        toast(t("LimitsRelease") + `"${userCartTypes[index]}"`, {
          className: "toast__error",
          bodyClassName: "toast__body",
          progressClassName: "toast__progressBar--error",
        });
        return;
      }
    }
    localStorage.setItem("userCartQuantities", JSON.stringify(quantities));
    localStorage.setItem("userCartLimits", JSON.stringify(limits));

    setQuantities(quantities);
    setUserCartLimits(limits);
  };

  const countTotal = () => {
    let total = 0;
    let index = 0;
    if (userCart?.length > 0) {
      for (const { usd, uah } of userCart) {
        if (i18n.language === "uk") {
          total +=
            uah !== 0
              ? roundToHigherOrder(uah * quantities[index])
              : roundToHigherOrder(usd * exchangeRate * quantities[index]);
          index += 1;
        } else {
          total += usd * quantities[index];
        }
      }
    }
    return total;
  };

  const onItemDeleteButtonClick = (index: number) => {
    setUserCartIds((prev) => {
      const item = [...prev];
      item.splice(index, 1);
      localStorage.setItem("userCartIds", JSON.stringify(item));
      return item;
    });
    setUserCartTypes((prev) => {
      const item = [...prev];
      item.splice(index, 1);
      localStorage.setItem("userCartTypes", JSON.stringify(item));
      return item;
    });
    setUserCartColors((prev) => {
      const item = [...prev];
      item.splice(index, 1);
      localStorage.setItem("userCartColors", JSON.stringify(item));
      return item;
    });
    setUserCartSizes((prev) => {
      const item = [...prev];
      item.splice(index, 1);
      localStorage.setItem("userCartSizes", JSON.stringify(item));
      return item;
    });
    setQuantities((prev) => {
      const item = [...prev];
      item.splice(index, 1);
      localStorage.setItem("userCartQuantities", JSON.stringify(item));
      return item;
    });

    setUserCartLimits((prev) => {
      const item: any = { ...prev };
      if (item[userCartTypes[index]]) {
        item[userCartTypes[index]] =
          +item[userCartTypes[index]] - +quantities[index];
      }

      localStorage.setItem("userCartLimits", JSON.stringify(item));
      return item;
    });
  };

  const onPayButtonClick = () => {
    let newOrder = "";
    userCart.map(
      ({ type, albumName, artistName, name, from, uah, usd }, index) => {
        const price =
          i18n.language === "uk"
            ? roundToHigherOrder(
                Math.round(uah !== 0 ? uah : usd * exchangeRate)
              ) + " грн"
            : usd + " usd";
        if (type === "merch") {
          newOrder += `${index + 1}. ${name + " " + from} — Колір ${
            userCartColors[index]
          }, Розмір ${userCartSizes[index]} (${
            quantities[index]
          } шт.): ${price} \n\n`;
        } else {
          newOrder += `${index + 1}. ${albumName + " " + artistName}  — (${
            quantities[index]
          } шт.): ${price} \n\n`;
        }
      }
    );

    setOrder(newOrder);
  };

  return (
    <>
      <Loader className={className} />
      {userCart && (
        <>
          <SEO title={t("CartPageTitleSEO")} noIndex />
          <div className="cart">
            {!processing && order && (
              <CartModal
                setOrder={setOrder}
                order={order}
                setProcessing={setProcessing}
                userCart={userCart}
                exchangeRate={exchangeRate}
                quantities={quantities}
                userCartSizes={userCartSizes}
                total={Math.round(countTotal())}
              />
            )}
            {processing && <Processing setProcessing={setProcessing} />}
            <div className="container__two">
              <Title title={t("CartTitle")} type={false} />
              <div className="cart__wrapper">
                <div className="cart__bill">
                  <p className="cart__bill--title">{t("CartReceipt")}</p>
                  <div className="cart__bill--line"></div>
                  <p className="cart__bill--total">
                    {t("CartInTotal")}:{" "}
                    <span className="cart__bill--sum">
                      {Math.round(countTotal())} {t("Currency")}
                    </span>
                  </p>
                  <button
                    type="button"
                    className="cart__bill--button button__hovering"
                    onClick={() => {
                      if (userCart?.length > 0) {
                        onPayButtonClick();
                      }
                    }}
                  >
                    {t("CartPay")}
                  </button>
                </div>
                {userCart?.map(
                  (
                    {
                      _id,
                      type,
                      availability,
                      amount,
                      coverPicture,
                      albumName,
                      artistName,
                      name,
                      from,
                      usd,
                      uah,
                      info,
                    }: {
                      _id: string;
                      type: string;
                      availability: string;
                      amount: number;
                      coverPicture: string;
                      albumName: string;
                      artistName: string;
                      name: string;
                      from: string;
                      usd: number;
                      uah: number;
                      info: [
                        {
                          xl: number;
                          l: number;
                          m: number;
                          s: number;
                          xs: number;
                          color: string;
                          image: string;
                          oneSizeAmount: number;
                        }
                      ];
                    },
                    index
                  ) => (
                    <div className="cart__item" key={_id}>
                      <div className="cart__top--wrapper">
                        <div className="cart__quantity--wrapper">
                          <Minus
                            onClick={() =>
                              updateQuantity(
                                index,
                                -1,
                                availability,
                                amount,
                                info
                              )
                            }
                            className="cart__cursor--pointer"
                          />
                          <div className="cart__top--quantity">
                            {quantities[index]} {t("CartQuantity")}
                          </div>
                          <Plus
                            onClick={() =>
                              updateQuantity(
                                index,
                                1,
                                availability,
                                amount,
                                info
                              )
                            }
                            className="cart__cursor--pointer"
                          />
                        </div>
                        <Cross
                          className="cart__cross--icon"
                          onClick={() => {
                            onItemDeleteButtonClick(index);
                          }}
                        />
                      </div>
                      <div className="cart__item--wrapper">
                        <img
                          src={
                            type === "merch"
                              ? info[
                                  findIndexByColor(info, userCartColors[index])
                                ]?.image
                                ? info[
                                    findIndexByColor(
                                      info,
                                      userCartColors[index]
                                    )
                                  ]?.image
                                : ""
                              : coverPicture
                          }
                          alt={type === "merch" ? name : albumName}
                          className={
                            type === "merch"
                              ? "cart__item--merch"
                              : "cart__item--image"
                          }
                        />
                        <div className="cart__item--thumb">
                          {type === "merch" ? (
                            <div className="cart__merch--wrapper">
                              <p className="cart__merch--sizeColor">
                                {t("CartSize")}:{" "}
                                <span className="cart__merch--sizeActive">
                                  {userCartSizes[index]}
                                </span>
                              </p>
                              <div className="cart__merch--thumb">
                                <p className="cart__merch--sizeColor">
                                  {t("CartColor")}:{" "}
                                </p>
                                <div className="cart__item--box"></div>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                          <p className="cart__item--artistName">
                            {type === "merch" ? name : artistName}
                          </p>
                          <p className="cart__item--albumName">
                            {type === "merch" ? from : albumName}
                          </p>
                          <p className="cart__item--price">
                            {i18n.language === "uk"
                              ? roundToHigherOrder(
                                  Math.round(
                                    uah !== 0 ? uah : usd * exchangeRate
                                  )
                                ) + ` ${t("Currency")}`
                              : usd + ` ${t("Currency")}`}
                          </p>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
