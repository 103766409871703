import { Suspense, useEffect, useState } from "react";
import { Header } from "../../components/Admin/Header";
import { Outlet } from "react-router-dom";
import { Loader } from "../../components/Loader/Loader";
import { login } from "../../services/axios";

export const Admin = () => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    const tryToLogin = async () => {
      const answer = await login();
      if (answer === "access") {
        setShow(true);
      }
    };

    tryToLogin();
  }, []);

  return (
    <>
      {show && (
        <>
          <Header />
          <Suspense fallback={<Loader className="" />}>
            <div className="admin__container">
              <Outlet />
            </div>
          </Suspense>
        </>
      )}
    </>
  );
};
