import { Swiper, SwiperSlide } from "swiper/react";
import { Title } from "../../components/Title/Title";
import "swiper/css";
import "swiper/swiper-bundle.css";
import { Navigation, Pagination } from "swiper/modules";
import SwiperCore from "swiper";
import { useEffect, useState } from "react";
import { getCassette, getUsdExchange, getVinyl } from "../../services/axios";
import { Cassette } from "../../interfaces/cassette";
import { Vinyl } from "../../interfaces/vinyl";
import { useParams } from "react-router-dom";
import { Loader } from "../../components/Loader/Loader";
import { useTranslation } from "react-i18next";
import { roundToHigherOrder } from "../../utils/roundToHigherOrder";
import { Recommendation } from "../../components/Recommendation/Recommendation";
import { SEO } from "../../components/SEO/SEO";
import { toast } from "react-toastify";

SwiperCore.use([Navigation, Pagination]);
export const Release = () => {
  const [direction, setDirection] = useState(true);
  const [data, setData] = useState<Vinyl | Cassette | null>(null);
  const [isMobile, setIsMobile] = useState(false);
  const [exchangeRate, setExchangeRate] = useState(41);
  const [className, setClassName] = useState("");

  const { i18n, t } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const { id } = useParams();

  useEffect(() => {
    const checkMobile = () =>
      /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    setIsMobile(checkMobile());
  }, []);

  useEffect(() => {
    const fetchExchanceRate = async () => {
      setExchangeRate(await getUsdExchange());
    };

    fetchExchanceRate();
  }, []);

  useEffect(() => {
    if (currentLanguage !== i18n.language) {
      window.location.href = `/release/${data?.otherLanguageId}`;
    }
  }, [data?.otherLanguageId, i18n.language, currentLanguage]);

  useEffect(() => {
    const fetchItem = async () => {
      const itemVinyl = await getVinyl(id, i18n.language);
      if (itemVinyl) {
        setData(itemVinyl);
      } else {
        const itemCassette = await getCassette(id, i18n.language);
        setData(itemCassette);
      }
      setTimeout(() => {
        setClassName("loader__hidden");
      }, 200);
    };

    fetchItem();
  }, [i18n.language, id, data?.otherLanguageId]);

  // useEffect(() => {
  //   window.location.href = `/release/${data?.otherLanguageId}`;
  // }, [i18n.language, data?.otherLanguageId]);

  const addToCart = () => {
    const userCartTypes = localStorage.getItem("userCartTypes");
    if (userCartTypes !== null) {
      const types = JSON.parse(userCartTypes);
      types.push(data?.type);
      localStorage.setItem("userCartTypes", JSON.stringify(types));
    } else {
      localStorage.setItem("userCartTypes", JSON.stringify([data?.type]));
    }
    const userCartColors = localStorage.getItem("userCartColors");
    if (userCartColors !== null) {
      const colors = JSON.parse(userCartColors);
      colors.push("");
      localStorage.setItem("userCartColors", JSON.stringify(colors));
    } else {
      localStorage.setItem("userCartColors", JSON.stringify([""]));
    }
    const userCartSizes = localStorage.getItem("userCartSizes");
    if (userCartSizes !== null) {
      const sizes = JSON.parse(userCartSizes);
      sizes.push("");
      localStorage.setItem("userCartSizes", JSON.stringify(sizes));
    } else {
      localStorage.setItem("userCartSizes", JSON.stringify([""]));
    }

    const userCartQuantities = localStorage.getItem("userCartQuantities");
    if (userCartQuantities !== null) {
      const quantities = JSON.parse(userCartQuantities);
      quantities.push(1);
      localStorage.setItem("userCartQuantities", JSON.stringify(quantities));
    } else {
      localStorage.setItem("userCartQuantities", JSON.stringify([1]));
    }
  };

  const onButtonBuyClick = () => {
    const userCartIds = localStorage.getItem("userCartIds");
    if (userCartIds !== null) {
      const ids = JSON.parse(userCartIds);
      if (ids.includes(id)) {
        setTimeout(() => {
          window.location.href = "/cart";
        }, 500);
      } else {
        const userCartLimits: any = localStorage.getItem("userCartLimits");
        if (userCartLimits !== null) {
          if (data?.type) {
            const cartLimits = JSON.parse(userCartLimits);
            if (cartLimits[data?.type] < 5) {
              if (cartLimits[data?.type]) {
                cartLimits[data?.type] += 1;
              } else {
                cartLimits[data?.type] = 1;
              }
              localStorage.setItem(
                "userCartLimits",
                JSON.stringify(cartLimits)
              );
              ids.push(id);
              localStorage.setItem("userCartIds", JSON.stringify(ids));
              setTimeout(() => {
                window.location.href = "/cart";
              }, 500);
              addToCart();
            } else {
              toast(t("LimitsMerch"), {
                className: "toast__error",
                bodyClassName: "toast__body",
                progressClassName: "toast__progressBar--error",
              });
            }
          }
        } else {
          if (data?.type) {
            const limits: any = {
              vinyl: 0,
              cassette: 0,
              merch: 0,
            };
            limits[data?.type] = 1;
            localStorage.setItem("userCartLimits", JSON.stringify(limits));
          }
          ids.push(id);
          localStorage.setItem("userCartIds", JSON.stringify(ids));
          setTimeout(() => {
            window.location.href = "/cart";
          }, 500);
          addToCart();
        }
      }
    } else {
      if (data?.type) {
        const limits: any = {
          vinyl: 0,
          cassette: 0,
          merch: 0,
        };
        limits[data?.type] = 1;
        localStorage.setItem("userCartLimits", JSON.stringify(limits));
      }
      localStorage.setItem("userCartIds", JSON.stringify([id]));
      setTimeout(() => {
        window.location.href = "/cart";
      }, 500);
      addToCart();
    }
  };

  return (
    <>
      <Loader className={className} />
      {data && (
        <>
          <SEO
            title={
              `${data?.artistName} - ${data?.albumName}. ` +
              t("ReleasePageTitleSEO")
            }
            description={
              `${t("ReleasePageDescriptionTwoSEO")} ${data?.artistName} - ${
                data?.albumName
              }. ` + t("ReleasePageDescriptionSEO")
            }
            link={`release/${id}`}
            keywords={data?.tags}
            imageCard={data?.coverPicture}
          />
          <section className="release">
            <div className="container__two">
              <Title
                title={t("ReleaseTitle")}
                type={false}
                direction={direction}
                setDirection={setDirection}
              />
              <div className="release__container">
                {data && (
                  <Swiper
                    className="swiper release__swiper"
                    spaceBetween={2500}
                    navigation={{
                      nextEl: ".swiper-button-next",
                      prevEl: ".swiper-button-prev",
                    }}
                    allowTouchMove={isMobile}
                    loop={true}
                    pagination={{
                      clickable: true,
                    }}
                    slidesPerView={1}
                    onSlideChange={() => {}}
                    onSwiper={(swiper: any) => {}}
                  >
                    <SwiperSlide>
                      <img
                        src={data?.coverPicture}
                        alt={data?.albumName}
                        className="release__image"
                      />
                    </SwiperSlide>
                    {data?.otherPictures.map((item: string, index) => (
                      <SwiperSlide key={index}>
                        <img
                          key={item}
                          src={item}
                          alt={data?.albumName}
                          className={
                            data?.type === "vinyl"
                              ? "release__image release__image--animation"
                              : "release__image"
                          }
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                )}
                <div className="release__wrapper">
                  <h2 className="release__title">
                    {data?.artistName} - {data?.albumName}
                  </h2>
                  <p
                    className="release__availability"
                    style={{
                      color:
                        data?.availability === "inStock"
                          ? "#35AFA3"
                          : data?.availability === "preOrder"
                          ? "#F5CD22"
                          : "#FB4839",
                    }}
                  >
                    {data?.availability === "inStock"
                      ? t("ReleaseInStock")
                      : data?.availability === "preOrder"
                      ? t("ReleasePreOrder")
                      : t("ReleaseOutOfStock")}
                  </p>
                  <p className="release__subtitle">
                    {t("ReleaseLabel")}:
                    <span className="release__span"> {data?.labelName}</span>
                  </p>
                  <p className="release__subtitle">
                    {t("ReleaseIssue")}:
                    <span className="release__span"> {data?.issue}</span>
                  </p>
                  <p className="release__subtitle">
                    {t("ReleaseCountry")}:
                    <span className="release__span"> {data?.country}</span>
                  </p>
                  <p className="release__subtitle">
                    {t("ReleaseGenreStyle")}:
                    <span className="release__span"> {data?.genre}</span>
                  </p>
                  <p className="release__price">
                    {i18n.language === "uk"
                      ? data?.uah !== 0
                        ? data?.uah
                        : roundToHigherOrder(exchangeRate * data.usd)
                      : data?.usd}
                    {""}
                    {t("Currency")}
                    {i18n.language === "uk" &&
                      (data?.uahSale !== 0 || data?.usdSale !== 0) && (
                        <span className="release__price--sale">
                          <s>
                            {data?.uahSale !== 0
                              ? data?.uahSale
                              : roundToHigherOrder(
                                  data?.uahSale * exchangeRate
                                )}
                            {""}
                            {t("Currency")}
                          </s>
                        </span>
                      )}
                    {i18n.language === "en" && data?.usdSale !== 0 && (
                      <span className="release__price--sale">
                        <s>
                          {data?.usdSale}
                          {""}
                          {t("Currency")}
                        </s>
                      </span>
                    )}
                  </p>
                  <button
                    type="button"
                    className="release__button button__hovering"
                    onClick={() => {
                      if (data?.availability === "outOfStock") {
                        return;
                      }
                      onButtonBuyClick();
                    }}
                  >
                    {data?.availability === "inStock"
                      ? t("ButtonAvailabilityInStock")
                      : data?.availability === "preOrder"
                      ? t("ButtonAvailabilityPreOrder")
                      : t("ButtonAvailabilityOutOfStock")}
                  </button>
                  {data?.tracklist && (
                    <div
                      className="release__tracklist"
                      dangerouslySetInnerHTML={{ __html: data?.tracklist }}
                    ></div>
                  )}
                </div>
              </div>
              {data?.links.length > 0 && data?.links[0] && (
                <>
                  <p className="release__recommendation">
                    {t("RecommendationTitle")}:
                  </p>
                  <Recommendation recommendationList={data?.links} />
                </>
              )}
            </div>
          </section>
        </>
      )}
    </>
  );
};
